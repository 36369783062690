<template>
  <div class="container flex flex-direction">
    <el-row style="width: 100%">
      <el-col :span="24" class="header-pic">
        <el-image
          :src="require('@/assets/img/activityMatch/match_bj.png')"
          fit="cover"
          style="width: calc(100% + 1rem); height: 100%; z-index: 0"
        ></el-image>
      </el-col>
    </el-row>
    <div class="content1 self-fixedWidth">
      <el-image
        :src="require('@/assets/img/activityMatch/match_content_1.png')"
        fit="fill"
        style="width: 100%; height: 100%; z-index: 0"
      ></el-image>
    </div>
     <div class="content2 self-fixedWidth">
      <el-image
        :src="require('@/assets/img/activityMatch/match_content_2.png')"
        fit="fill"
        style="width: 100%; height: 100%; z-index: 0"
      ></el-image>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
::v-deep .el-button--warning {
  background: #f08519;
  border-color: #f08519;
}

.padding-lr-20 {
  padding: 0 15vw;
}

.container {
  // margin-top: -1rem;
  // margin-bottom: -1rem;
  margin-top: 2px;

  /* 具体内容 */
  .content1 {
    margin-top: 4rem;
    width: 100%;
    height: 100%;
  }

  .content2 {
    margin-top: 10rem;
    margin-bottom: 7rem;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 764px) {
  .self-wrap-class {
    .el-main {
      .container {
        width: 100% !important;
        min-width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        .self-fixedWidth {
          width: 100% !important;
          min-width: 100% !important;
          // margin: 0 !important;
          // padding: 0 !important;
        }
        .content1 {
          margin-top: 2rem;
          padding:0 1rem ;
          width: 100%;
          height: 100%;
        }
        .content2 {
           margin-top: 2rem;
          // margin-bottom: 5rem;
          padding:0 1rem ;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px){
.self-wrap-class{
  .el-main{
    .container{
        width: 100%!important;
        min-width: 100%!important;
        // margin: 0!important;
        // padding: 0!important;
      .self-fixedWidth{
        width: 100%!important;
        min-width: 100%!important;
        // margin: 0!important;
        // padding: 0!important;
      }

       .content1 {
          margin-top: 2rem;
          padding:0 3rem ;
          width: 100%;
          height: 100%;
        }
        .content2 {
          margin-bottom: 5rem;
          padding:0 3rem ;
          width: 100%;
          height: 100%;
        }
    }
  }
}
}
</style>
